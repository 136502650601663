<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Last updated
								</th>
								<th scope="col" class="relative px-6 py-3"></th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="row in companies" :key="row.company_id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ row.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										{{ row.formatted_last_updated }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<v-button
										@click="viewCompany(row.company_id)"
										class="btn btn-default-outline btn-small">
										View
									</v-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import Button from "@/components/ui/Button";
import { mapGetters } from "vuex";

export default {
	created() {},

	mounted() {
		this.getCompanyList();
	},

	components: {
		"v-button": Button,
		// Modal,
	},

	data() {
		return {
			companies: [],
		};
	},

	methods: {
		viewCompany(company_id) {
			this.$router.push({
				name: "company-analysis-project-company",
				params: {
					teamId: this.teamId,
					projectId: this.projectId,
					companyId: company_id,
				},
			});
		},

		async getCompanyList() {
			try {
				let response = await this.$api
					.resource("companyAnalysisProjectCompanyList", {
						team_id: this.teamId,
						project_id: this.projectId,
					})
					.fetchAll();

				for (var i in response.data.companies) {
					response.data.companies[i].formatted_last_updated = moment(
						response.data.companies[i].last_updated
					).format("ddd, MMM Do YYYY, HH:mm:ss");
				}
				this.companies = response.data.companies;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},
	},

	props: ["teamId", "projectId"],

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>
