<template>
	<form @submit.prevent="save" class="form-bare">
		<label class="flex flex-col font-bold w-full">
			<span>Group name*</span>
			<input
				class="border form-input mt-1 p-2"
				name="name"
				v-model="resource.name"
				placeholder="Group name"
				required />

			<!-- <span class="mt-2">Company number</span> -->
			<!-- <input class="border form-input mt-1 p-2" name="name" v-model="resource.company_number" placeholder="Company number"> -->

			<!-- <span class="mt-2">Postcode</span> -->
			<!-- <input class="border form-input mt-1 p-2" name="name" v-model="resource.postcode" placeholder="Postcode"> -->

			<span class="mt-2">Domains</span>
			<span class="text-xs font-normal">One domain per line</span>
			<textarea
				class="border form-input mt-1 p-2"
				name="name"
				placeholder="Domains"
				rows="4"
				@keyup="domains_update"></textarea>
		</label>
		<v-button type="submit" class="btn btn-success" :loading="crudLoading">Add group</v-button>
	</form>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";

export default {
	mixins: [crudable],

	mounted() {
		this.setResourceEndpoint("companyAnalysisProjectCompanyList", {
			team_id: this.teamId,
			project_id: this.projectId,
		});
	},

	components: {
		"v-button": Button,
	},

	data() {
		return {};
	},

	props: ["teamId", "projectId"],

	methods: {
		domains_update(e) {
			this.resource.domains = e.target.value.split("\n");
		},
	},

	computed: {
		crudName() {
			if (this.resourceId) {
				return "Save";
			}
			return "Create";
		},
	},
};
</script>
<style></style>
